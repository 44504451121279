import React, { useContext } from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"

import { IProduct } from "../graph"
import Layout from "../layout"
import { Message, LangContext, Money } from "../components/l10n"
import { CartContext } from "../components/cart"
import CloseIcon from "../images/close"
import RemoveIcon from "../images/remove"
import AddIcon from "../images/add"
import { Content, Title, buttonCss } from "../components/styled"
import SquareImage from "../components/squareimage"

const Th = styled.th<{ small?: boolean; optional?: boolean }>`
  color: #9b9b9b;
  font-size: 20px;
  padding: 10px 0;
  font-weight: normal;
  width: ${(props) => (props.small ? "65px" : "200px")};
  @media (max-width: 999px) {
    width: ${(props) => (props.small ? "35px" : "90px")};
    ${(props) => (props.optional ? "display: none;" : "")}
    font-size: 14px;
  }
  @media (max-width: 399px) {
    width: ${(props) => (props.small ? "35px" : "70px")};
    letter-spacing: 0;
  }
`
const Cell = styled.td<{ optional: boolean }>`
  @media (max-width: 999px) {
    ${(props) => (props.optional ? "display: none;" : "")}
  }
`
const RemoveButton = styled.button`
  svg {
    width: 18px;
    height: 18px;
    fill: #9b9b9b;
  }
`
const ImageLink = styled(Link)`
  display: block;
  width: 65px;
  height: 65px;
  margin: 10px 0;
  @media (max-width: 699px) {
    width: 45px;
    height: 45px;
  }
  @media (max-width: 699px) {
    width: 35px;
    height: 35px;
  }
`
const NameCell = styled.td`
  font-family: "Playfair Display", serif;
  font-size: 20px;
  padding: 0 20px;
  @media (max-width: 699px) {
    font-size: 16px;
    padding: 0 10px;
  }
  @media (max-width: 399px) {
    font-size: 14px;
    max-width: 70px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`
const Quantity = styled.div`
  white-space: nowrap;
  vertical-align: middle;
  box-sizing: border-box;
  display: inline-block;
  border: 1px solid #d8d8d8;
  padding: 0 4px;
  button {
    padding: 8px;
  }
  span {
    margin: 0 10px;
  }
  @media (max-width: 699px) {
    span {
      margin: 0;
      font-size: 14px;
    }
  }
  @media (max-width: 399px) {
    button {
      padding: 8px 2px;
    }
    svg {
      width: 16px;
      height: 16px;
    }
  }
`
const PriceCell = styled.td`
  text-align: center;
  white-space: nowrap;
  @media (max-width: 399px) {
    font-size: 14px;
    max-width: 10px;
  }
`

const Bottom = styled.div`
  margin: 30px 0;
  border-top: 1px solid #d8d8d8;
  padding: 20px 0;
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  text-align: right;
  @media (max-width: 399px) {
    margin: 20px 0;
  }
`
const Shipping = styled.div`
  white-space: nowrap;
  flex: 1;
  margin-top: 20px;
  line-height: 24px;
  color: #9b9b9b;
  @media (max-width: 799px) {
    margin-top: 0;
  }
  @media (max-width: 399px) {
    font-size: 14px;
  }
  @media (min-width: 400px) {
    br {
      display: none;
    }
  }
  @media (min-width: 950px) {
    text-align: left;
  }
`
const ShippingOption = styled.span`
  margin: 0 12px;
  &:last-child {
    margin-right: 0;
  }
`
const Subtotal = styled.div`
  white-space: nowrap;
  flex: 1;
  font-size: 20px;
  margin: 20px 50px;
  span {
    margin-left: 20px;
    font-size: 22px;
  }
  @media (max-width: 999px) {
    margin-right: 0;
  }
  @media (max-width: 399px) {
    font-size: 16px;
    span {
      font-size: 19px;
      vertical-align: -1px;
    }
  }
`
const CheckoutButton = styled(Link)`
  ${buttonCss}
  float: right;
  margin: 20px 50px 50px;
  @media (max-width: 999px) {
    margin: 0px 0px 30px;
  }
`

const CartPage = ({ data }) => {
  const { cart, remove, sub, add, total } = useContext(CartContext)
  const { lang } = useContext(LangContext)

  const products: IProduct[] = data.products.edges
    .map(({ node }) => node)
    .filter(({ node_locale }) => node_locale.indexOf(lang) === 0)

  const handleRemove = (slug: string) => () => {
    remove(slug)
  }
  const handleSub = (slug: string) => () => {
    sub(slug, 1)
  }
  const handleAdd = (slug: string) => () => {
    add(slug, 1)
  }

  const subtotal = total()

  const { domesticCountry, shippingDomestic, shippingInternational } = data.site.siteMetadata

  return (
    <Layout noindex>
      <Content>
        <Title>
          <Message id="cart" />
        </Title>
        {cart.products.length > 0 ? (
          <>
            <table>
              <thead>
                <tr>
                  <Th small optional />
                  <Th small>
                    <Message id="item" />
                  </Th>
                  <th />
                  <Th small>
                    <Message id="quantity" />
                  </Th>
                  <Th>
                    <Message id="price" />
                  </Th>
                </tr>
              </thead>
              <tbody>
                {cart.products.map(({ slug, quantity }) => {
                  const { thumbs, name, price } = products.find((product) => product.slug === slug)
                  const path = `/product/${slug}/`

                  return (
                    <tr key={slug}>
                      <Cell optional>
                        <RemoveButton onClick={handleRemove(slug)}>
                          <CloseIcon />
                        </RemoveButton>
                      </Cell>
                      <td>
                        <ImageLink to={path}>
                          <SquareImage fluid={thumbs[0].fluid} />
                        </ImageLink>
                      </td>
                      <NameCell>
                        <Link to={path}>{name}</Link>
                      </NameCell>
                      <td>
                        <Quantity>
                          <button onClick={handleSub(slug)}>
                            <RemoveIcon />
                          </button>
                          <span>{quantity}</span>
                          <button onClick={handleAdd(slug)}>
                            <AddIcon />
                          </button>
                        </Quantity>
                      </td>
                      <PriceCell>
                        <Money amount={price * quantity} />
                      </PriceCell>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <Bottom>
              <Shipping>
                <Message id="shipping" Tag="strong" />
                <br />
                <ShippingOption>
                  {domesticCountry} <Money amount={shippingDomestic} />
                </ShippingOption>
                |
                <ShippingOption>
                  <Message id="international" /> <Money amount={shippingInternational} />
                </ShippingOption>
              </Shipping>
              <Subtotal>
                <Message id="subtotal" />
                <Money amount={subtotal} Tag="span" />
              </Subtotal>
            </Bottom>
            {subtotal > 0 && (
              <CheckoutButton to="/checkout/">
                <Message id="checkout" />
              </CheckoutButton>
            )}
          </>
        ) : (
          <Message id="cart_empty" Tag="p" />
        )}
      </Content>
    </Layout>
  )
}

export const query = graphql`
  query Cart {
    products: allContentfulProduct {
      edges {
        node {
          node_locale
          name
          slug
          price
          thumbs: photos {
            fluid(maxWidth: 65, quality: 90) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        domesticCountry
        shippingDomestic
        shippingInternational
      }
    }
  }
`

export default CartPage
